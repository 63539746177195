import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function GnocchiModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Gnocchi in Marinara with Pork Loin (Compound Butter: Garlic, parsley,
          lemon zest)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              You hate gnocchi so never make it again. The texture is not what
              you imagine it to be. It's gummy, and just not good. Choose any
              other pasta.
            </li>
            <li>
              Compound butter is amazing. Adds a crazy amonut of flavour to the
              meat and was the saving grace. Probably would be better with steak
              but was still good regardless.
            </li>
            <li>
              Marinara is as usual. Fry up spices, garlic, tomato paste, onions.
              Then dump that into a put of simmering tomatoes
            </li>
            <li>
              <i>
                No specific recipes were followed here. Compound butter was inspired by
                MasterChef Season 4 Episode 19 (Jordan)
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default GnocchiModal;
