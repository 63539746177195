import React from 'react';
import Button from 'react-bootstrap/Button';
import cambodia from '../../resources/Travel_Images/Cambodia.JPG';
import thailand from '../../resources/Travel_Images/Thailand.JPG';
import vietnam from '../../resources/Travel_Images/Vietnam.JPG';
import france from '../../resources/Travel_Images/France.JPG';
import denmark from '../../resources/Travel_Images/Denmark.JPG';
import macau from '../../resources/Travel_Images/Macau.JPG';

import CambodiaModal from './Modals/cambodiaModal';
import ThailandModal from './Modals/thailandModal';
import VietnamModal from './Modals/vietnamModal';
import FranceModal from './Modals/franceModal';
import DenmarkModal from './Modals/denmarkModal';
import MacauModal from './Modals/macauModal';

function About() {
  const [cambodiaModalShow, setCambodiaModalShow] = React.useState(false);
  const [thailandModalShow, setThailandModalShow] = React.useState(false);
  const [vietnamModalShow, setVietnamModalShow] = React.useState(false);
  const [franceModalShow, setFranceModalShow] = React.useState(false);
  const [denmarkModalShow, setDenmarkModalShow] = React.useState(false);
  const [macauModalShow, setMacauModalShow] = React.useState(false);

  return (
    <>
      <div class="containerTravel">
        <div class="gallery">
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="cambodia-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={cambodia}
                onClick={() => setCambodiaModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="thailand-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={thailand}
                onClick={() => setThailandModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="vietnam-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={vietnam}
                onClick={() => setVietnamModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="macau-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={macau}
                onClick={() => setMacauModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="france-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={france}
                onClick={() => setFranceModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>{' '}
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>{' '}
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>{' '}
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="denmark-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={denmark}
                onClick={() => setDenmarkModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
        </div>
      </div>
      <CambodiaModal
        show={cambodiaModalShow}
        onHide={() => setCambodiaModalShow(false)}
      />
      <ThailandModal
        show={thailandModalShow}
        onHide={() => setThailandModalShow(false)}
      />
      <VietnamModal
        show={vietnamModalShow}
        onHide={() => setVietnamModalShow(false)}
      />
      <FranceModal
        show={franceModalShow}
        onHide={() => setFranceModalShow(false)}
      />
      <DenmarkModal
        show={denmarkModalShow}
        onHide={() => setDenmarkModalShow(false)}
      />
      <MacauModal
        show={macauModalShow}
        onHide={() => setMacauModalShow(false)}
      />
    </>
  );
}

export default About;
