import React from 'react';
import Header from './header';
import Message from './message';
import Footer from '../footer';

const NotFoundPage = () => {
  return (
    <div id="errorPage">
      <Header />
      <Message />
      <Footer />
    </div>
  );
};

export default NotFoundPage;
