import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function AwaseDashiModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Homemade Awase Dashi with Seared Wagyu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Can always double or triple this recipe, extra broth can just be
              placed in the freezer, this recipe makes for 2 servings
            </li>
            <li>
              Soak one piece of kombu (2" x 5") in 2.5 cups of water for 3 hours
              to a half a day. Bring to a low simmer. Remove kombu right before
              it starts to boil or broth will get bitter.
            </li>
            <li>
              Place katsuobushi into broth, simmer for 15 more seconds, remove
              katsuobushi after 10-15 minutes. This gives it the smokey flavour
            </li>
            <li>
              <i>
                Followed Just One Cookbook’s recipe{' '}
                <a href="https://www.justonecookbook.com/kitsune-udon/">
                  [https://www.justonecookbook.com/kitsune-udon/]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AwaseDashiModal;
