import React from 'react';
import KennethChanResume from '../resources/KennethChanResume.pdf';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div class="d-flex flex-column">
      <footer class="footer">
        <p>Kenneth Chan ⓒ {year}</p>
        <p>
          E-mail:{' '}
          <a href="mailto: kenneth.chan@queensu.ca">kenneth.chan@queensu.ca</a>{' '}
          &diams; LinkedIn:{' '}
          <a href="https://www.linkedin.com/in/kennethcwchan/">
            linkedin.com/in/kennethcwchan/
          </a>{' '}
          &diams; <a href={KennethChanResume}> Resume </a>
        </p>
      </footer>
    </div>
  );
}

export default Footer;
