import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

//Pages
import Home from './components/Home/home';
import Travel from './components/Travel/travel';
import Recipe from './components/Recipe/recipe';
import NotFoundPage from './components/404/404';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/travel" component={Travel} />
          <Route exact path="/delizioso" component={Recipe} />
          <Route exact path="/404" component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    );
  }
}

export default App;
