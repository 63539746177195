import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function KatsuModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cream Corn Chicken Katsu
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Shallow fry works for chicken katsu. It tastes amazing and not as
              unhealthy.
            </li>
            <li>
              Season the flour generously. Dredging station means coating food
              with flour. That's the first thing to do here. After pounding out
              the chicken with a pan, dry the chicken with some paper towel,
              season, and coat in the flour. Shake off excess flour, egg wash,
              then bread crumbs.
            </li>
            <li>
              <i>Followed Chris Morocco’s recipe</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default KatsuModal;
