import React from 'react';
import signature from '../../resources/signature.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <Navbar bg="anitquewhite" expand="lg" sticky="top" className="navbar">
      <Navbar.Brand href="/">
        <img
          src={signature}
          width="150"
          height="40"
          className="d-inline-block align-top"
          alt="Kenneth Chan logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/delizioso">Recipe</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
