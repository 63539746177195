import React from 'react';
import signature from '../../resources/signature.png';
import { scroller, animateScroll as scroll } from 'react-scroll';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
  return (
    <Navbar bg="anitquewhite" expand="lg" sticky="top" className="navbar">
      <Navbar.Brand href="/">
        <img
          src={signature}
          width="150"
          height="40"
          className="d-inline-block align-top"
          alt="Kenneth Chan logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            onClick={() =>
              scroller.scrollTo('main', {
                smooth: true,
                offset: -70,
                duration: 500,
              })
            }
          >
            Home
          </Nav.Link>
          <Nav.Link
            onClick={() =>
              scroller.scrollTo('introduction', {
                smooth: true,
                offset: -70,
                duration: 500,
              })
            }
          >
            Introduction
          </Nav.Link>
          <Nav.Link
            onClick={() =>
              scroller.scrollTo('about', {
                smooth: true,
                offset: -70,
                duration: 500,
              })
            }
          >
            About
          </Nav.Link>
          <NavDropdown title="Hobbies" id="basic-nav-dropdown">
            <NavDropdown.Item href="/travel" className="dropdownText">
              Travel
            </NavDropdown.Item>
            <NavDropdown.Item href="/delizioso" className="dropdownText">
              Cooking
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
