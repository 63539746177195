import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function EmpanadaModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Empanadas three-way - chorizo and onions, minced beef and red peppers,
          ham and cheese
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Pictures were taken by Chef Karrot. Filling and dough was also
              made by Chef Karrot.
            </li>
            <li>
              Never give up. Rolling out 30 empanada shells is tough but it is
              so worth it. Empanadas are amazing.
            </li>
            <li>
              Filling should be a little bit more salty to accomodate for the
              pastries.
            </li>
            <li>
              Chorizo and onions was by far the best filling. Minced beef was
              also outstanding.
            </li>
            <li>
              <i>Followed Chef Karrot's Direction</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmpanadaModal;
