import React from 'react';
import { useState, useEffect } from 'react';
import shark from '../../resources/shark.png';

function Main() {
  // useState hooks
  // window.onload = function () {

  // };

  // const getRandomPoint = (elementWidth, elementHeight) => {
  //   let x = (Math.random() * (window.innerWidth - elementWidth)).toFixed();
  //   let y = (Math.random() * (window.innerHeight - elementHeight)).toFixed();

  //   return {
  //     x: x + 'px',
  //     y: y + 'px',
  //   };
  // };

  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({
      clientX: 0,
      clientY: 0,
    });

    const updateMousePosition = (event) => {
      const { clientX, clientY } = event;
      setMousePosition({ clientX, clientY });
    };

    useEffect(() => {
      document.addEventListener('mousemove', updateMousePosition, false);
      document.addEventListener('mouseenter', updateMousePosition, false);

      return () => {
        document.removeEventListener('mousemove', updateMousePosition);
        document.removeEventListener('mouseenter', updateMousePosition);
      };
    }, []);

    return mousePosition;
  };

  const { clientX, clientY } = useMousePosition();

  const hasMovedCursor =
    typeof clientX === 'number' && typeof clientY === 'number';

  return (
    <div class="main">
      <div class="topLetterContainer">
        <div class="letterContainer">
          <h1 id="letter">K</h1>
          <h1 id="letter">E</h1>
          <h1 id="letter">N</h1>
        </div>

        <div class="paragraph">
          <p>
            Kenneth, [&kappa;&epsilon;&eta;-&Iota;&Theta;], <i>proper noun</i>,
            enthusiastic and ambitious individual who is currently pursuing a
            career in Project Management, IT Consulting, or Web Development.
            {/* {hasMovedCursor
            ? `your cursor is at ${x}, ${y}`
            : 'move that bitch around'} */}
          </p>
        </div>
      </div>
      <div class="wowFactor vertical-bottom">
        <a href="http://www.google.com/" target="_blank">
          <img
            alt="sharkpic"
            src={shark}
            class="shark-container"
            id="shark-container"
          />
          <div class="shark-mover" id="shark-mover"></div>
        </a>
      </div>

      {/* <div
        class="wowFactorSecondTry"
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          pointerEvents: 'none',
        }}
      >
        <svg
          width={300}
          height={150}
          viewBox="0 0 150 300"
          style={{
            position: 'absolute',
            left: clientX,
            top: clientY,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <image alt="sharkpic" href={shark} height="150" width="300" />
        </svg>
      </div> */}
    </div>
  );
}

export default Main;
