import React from 'react';

function Introduction() {
  return (
    <div class="introduction">
      <div className="vertical-center">
        <h1 id="hello">Hello</h1>
        <h2 id="whoami">I am Kenneth</h2>
        <p id="description">
          You have stumbled upon something that I am proud to have represent a
          chapter of my life
        </p>
      </div>
    </div>
  );
}

export default Introduction;
