import React from 'react';
import Header from './header';
import Footer from '../footer';
import Introduction from './introduction';
import About from './about';

function Recipe() {
  return (
    <>
      <div className="home">
        <Header />
        <Introduction />
        <About />
        <Footer />
      </div>
    </>
  );
}

export default Recipe;
