import React from 'react';
import Button from 'react-bootstrap/Button';
import education from '../../resources/Home_Page/Education.png';
import skills from '../../resources/Home_Page/Skills.jpg';
import workExperience from '../../resources/Home_Page/workExperience.jpg';
import EducationModal from './Modals/educationModal';
import ExperienceModal from './Modals/experienceModal';
import SkillsModal from './Modals/skillsModal';

function About() {
  const [educationModalShow, setEducationModalShow] = React.useState(false);
  const [skillsModalShow, setSkillsModalShow] = React.useState(false);
  const [experienceModalShow, setExperienceModalShow] = React.useState(false);

  return (
    <>
      <div class="about">
        <div class="containerHome">
          <div class="gallery">
            <div class="gallery-item" tabindex="0">
              <Button
                variant="primary"
                className="gallery-buttimage"
                alt="education-btn"
              >
                <input
                  type="image"
                  class="gallery-image"
                  src={education}
                  onClick={() => setEducationModalShow(true)}
                />
              </Button>
              <div class="gallery-item-info"></div>
            </div>
            <div class="gallery-item" tabindex="0">
              <Button
                variant="primary"
                className="gallery-buttimage"
                alt="skills-btn"
              >
                <input
                  type="image"
                  class="gallery-image"
                  src={skills}
                  onClick={() => setSkillsModalShow(true)}
                />
              </Button>
              <div class="gallery-item-info"></div>
            </div>
            <div class="gallery-item" tabindex="0">
              <Button
                variant="primary"
                className="gallery-buttimage"
                alt="experience-btn"
              >
                <input
                  type="image"
                  class="gallery-image"
                  src={workExperience}
                  onClick={() => setExperienceModalShow(true)}
                />
              </Button>
              <div class="gallery-item-info"></div>
            </div>
          </div>
        </div>
      </div>
      <EducationModal
        show={educationModalShow}
        onHide={() => setEducationModalShow(false)}
      />
      <SkillsModal
        show={skillsModalShow}
        onHide={() => setSkillsModalShow(false)}
      />
      <ExperienceModal
        show={experienceModalShow}
        onHide={() => setExperienceModalShow(false)}
      />
    </>
  );
}

export default About;
