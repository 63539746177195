import React from 'react';
import Header from './header';
import Footer from '../footer';
import Main from './main';
import Introduction from './introduction';
import About from './about';

function Home() {
  return (
    <>
      <div className="home">
        <Header />
        <Main />
        <Introduction />
        <About />
        <Footer />
      </div>
    </>
  );
}

export default Home;
