import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RisottoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pesto Cream Risotto with Pan-Seared Chicken Breast
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              1 cup of Arborio is enough for two people (3 cups of chicken broth
              can infuse rosemary and other spices)
            </li>
            <li>
              Make sure rice is slightly translucent before white wine is added
            </li>
            <li>
              1/2 of heavy cream needed only (Add basil paste for pesto flavour)
            </li>
            <li>
              <i>Followed Binging with Babish’s recipe</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RisottoModal;
