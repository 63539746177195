import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function SkillsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Skills</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Development</h4>
        <p>
          <ul>
            <li>
              Programming Languages: JavaScript, VB, Python, Java, C++, C, HTML,
              CSS
            </li>
            <li>
              Frameworks and Libraries: React, Node, TensorFlow, Bootstrap,
              jQuery
            </li>
            <li>Databases: SQL, MongoDB, Apache Cassandra</li>
            <li>Languages: English, Cantonese, French, Mandarin</li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SkillsModal;
