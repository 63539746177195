import React from 'react';
import Button from 'react-bootstrap/Button';
import eggsbenedict from '../../resources/Recipe_Images/eggsBenedict.JPG';
import basilcreamrisotto from '../../resources/Recipe_Images/basilCreamRisotto.JPG';
import tomatoBasilGnocchi from '../../resources/Recipe_Images/tomatoBasilGnocchi.JPG';
import creamCornKatsu from '../../resources/Recipe_Images/creamCornKatsu.JPG';
import awaseDashi from '../../resources/Recipe_Images/awaseDashi.JPG';
import danDanNoodles from '../../resources/Recipe_Images/danDanNoodles.JPG';
import hummus from '../../resources/Recipe_Images/hummus.JPG';
import empanadas from '../../resources/Recipe_Images/empanadas.JPG';
import cookies from '../../resources/Recipe_Images/cookies.JPG';
import limonePasta from '../../resources/Recipe_Images/limonePasta.JPG';
import pineappleFriedRice from '../../resources/Recipe_Images/pineappleFriedRice.JPG';
import bibimbap from '../../resources/Recipe_Images/bibimbap.JPG';
import carbonara from '../../resources/Recipe_Images/carbonara.JPG';
import roastPotatoes from '../../resources/Recipe_Images/roastPotatoes.JPG';
import rempahLaksa from '../../resources/Recipe_Images/rempahLaksa.JPG';

import EggsBenedictModal from './Modals/eggsBenedictModal';
import RisottoModal from './Modals/risottoModal';
import GnocchiModal from './Modals/gnocchiModal';
import KatsuModal from './Modals/katsuModal';
import AwaseDashiModal from './Modals/awaseDashiModal';
import DanDanModal from './Modals/danDanModal';
import HummusModal from './Modals/hummusModal';
import EmpanadaModal from './Modals/empanadaModal';
import CookieModal from './Modals/cookieModal';
import LimonePastaModal from './Modals/limonePastaModal';
import PineappleFRModal from './Modals/pineappleFRModal';
import BibimbapModal from './Modals/bibimbapModal';
import CarbonaraModal from './Modals/carbonaraModal';
import RoastPotatoesModal from './Modals/roastPotatoesModal';
import RempahLaksaModal from './Modals/rempahLaksaModal';

function About() {
  const [eggsBenedictModalShow, setEggsBenedictModalShow] = React.useState(
    false
  );
  const [risottoModalShow, setRisottoModalShow] = React.useState(false);
  const [gnocchiModalShow, setGnocchiModalShow] = React.useState(false);
  const [katsuModalShow, setKatsuModalShow] = React.useState(false);
  const [awaseDashiModalShow, setAwaseDashiModalShow] = React.useState(false);
  const [danDanModalShow, setDanDanModalShow] = React.useState(false);
  const [hummusModalShow, setHummusModalShow] = React.useState(false);
  const [empanadaModalShow, setEmpanadaModalShow] = React.useState(false);
  const [cookieModalShow, setCookieModalShow] = React.useState(false);
  const [limonePastaModalShow, setLimonePastaModalShow] = React.useState(false);
  const [pineappleFRModalShow, setPineappleFRModalShow] = React.useState(false);
  const [bibimbapModalShow, setBibimbapModalShow] = React.useState(false);
  const [carbonaraModalShow, setCarbonaraModalShow] = React.useState(false);
  const [roastPotatoesModalShow, setRoastPotatoesModalShow] = React.useState(
    false
  );
  const [rempahLaksaModalShow, setRempahLaksaModalShow] = React.useState(false);

  return (
    <>
      <div class="containerRecipe">
        <div class="gallery">
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="eggsbenedict-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={eggsbenedict}
                onClick={() => setEggsBenedictModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="basilcreamrisotto-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={basilcreamrisotto}
                onClick={() => setRisottoModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"></div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="cookies-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={cookies}
                onClick={() => setCookieModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="creamCornKatsu-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={creamCornKatsu}
                onClick={() => setKatsuModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="awaseDashi-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={awaseDashi}
                onClick={() => setAwaseDashiModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="danDanNoodles-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={danDanNoodles}
                onClick={() => setDanDanModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="hummus-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={hummus}
                onClick={() => setHummusModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="empanadas-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={empanadas}
                onClick={() => setEmpanadaModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="tomatoBasilGnocchi-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={tomatoBasilGnocchi}
                onClick={() => setGnocchiModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>

          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="limonePasta-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={limonePasta}
                onClick={() => setLimonePastaModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="pineappleFriedRice-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={pineappleFriedRice}
                onClick={() => setPineappleFRModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="bibimbap-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={bibimbap}
                onClick={() => setBibimbapModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="carbonara-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={carbonara}
                onClick={() => setCarbonaraModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="roastPotatoes-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={roastPotatoes}
                onClick={() => setRoastPotatoesModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
          <div class="gallery-item" tabindex="0">
            <Button
              variant="primary"
              className="gallery-buttimage"
              alt="rempahLaksa-btn"
            >
              <input
                type="image"
                class="gallery-image"
                src={rempahLaksa}
                onClick={() => setRempahLaksaModalShow(true)}
              />
            </Button>
            <div class="gallery-item-info"> </div>
          </div>
        </div>
      </div>
      <EggsBenedictModal
        show={eggsBenedictModalShow}
        onHide={() => setEggsBenedictModalShow(false)}
      />
      <RisottoModal
        show={risottoModalShow}
        onHide={() => setRisottoModalShow(false)}
      />
      <GnocchiModal
        show={gnocchiModalShow}
        onHide={() => setGnocchiModalShow(false)}
      />
      <KatsuModal
        show={katsuModalShow}
        onHide={() => setKatsuModalShow(false)}
      />{' '}
      <AwaseDashiModal
        show={awaseDashiModalShow}
        onHide={() => setAwaseDashiModalShow(false)}
      />{' '}
      <DanDanModal
        show={danDanModalShow}
        onHide={() => setDanDanModalShow(false)}
      />{' '}
      <HummusModal
        show={hummusModalShow}
        onHide={() => setHummusModalShow(false)}
      />{' '}
      <EmpanadaModal
        show={empanadaModalShow}
        onHide={() => setEmpanadaModalShow(false)}
      />
      <CookieModal
        show={cookieModalShow}
        onHide={() => setCookieModalShow(false)}
      />
      <LimonePastaModal
        show={limonePastaModalShow}
        onHide={() => setLimonePastaModalShow(false)}
      />{' '}
      <PineappleFRModal
        show={pineappleFRModalShow}
        onHide={() => setPineappleFRModalShow(false)}
      />{' '}
      <BibimbapModal
        show={bibimbapModalShow}
        onHide={() => setBibimbapModalShow(false)}
      />{' '}
      <CarbonaraModal
        show={carbonaraModalShow}
        onHide={() => setCarbonaraModalShow(false)}
      />{' '}
      <RoastPotatoesModal
        show={roastPotatoesModalShow}
        onHide={() => setRoastPotatoesModalShow(false)}
      />{' '}
      <RempahLaksaModal
        show={rempahLaksaModalShow}
        onHide={() => setRempahLaksaModalShow(false)}
      />
    </>
  );
}

export default About;
