import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function CookieModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Brown Butter Chocolate Chip Cookies{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Preface: This is not my picture. This image belongs to Bon
              Appetit. I didn't have a good picture to show off like BA. But
              mine tasted great so trust me - this recipe is still good.
            </li>
            <li>
              Mix of two types of chocolate is nice. One bar of 50%, one bar of
              70%.
            </li>
            <li>Browning butter adds a unique nutty flavour to it. Amazing.</li>
            <li>
              Joshua Weissman drops the pan in the oven every 3-5 minutes. This
              stops the cookies from rising too much and halts the cookie from
              developing a cake texture.
            </li>
            <li>Flakey salt on top. Always.</li>
            <li>
              Let dough rest in the fridge for 1 hour to 24 hours. Gives it that
              beautiful wrinkle.
            </li>
            <li>
              <i>
                Followed Chris Morocco’s recipe{' '}
                <a href="https://www.bonappetit.com/recipe/bas-best-chocolate-chip-cookies">
                  [https://www.bonappetit.com/recipe/bas-best-chocolate-chip-cookies]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CookieModal;
