import React from 'react';
import travelShark from '../../resources/travelShark.png';

function Introduction() {
  return (
    <div class="containerTravel">
      <div class="profile">
        <div class="profile-image">
          <img src={travelShark} alt="" />
        </div>
        <div class="profile-bio">
          <p>
            This is a placeholder for something I am passionate about. Content
            is currently being created. Travelling is something that is done
            with friends and alone to find answers about yourself. This page
            will hold some of the places that I have visited in the past that I
            hold dearly in my heart.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
