import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function HummusModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Hummus</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Making a tahini by toasting some sesame seeds and blending it
              before hand with some extra virgin olive oil makes the biggest
              difference in the world.
            </li>
            <li>
              Roast 3-4 cloves of garlic depending on how garlicy you want it,
              and add that to the tahini paste.
            </li>
            <li>
              For the can of chickpeas, wash them a little, try to remove excess
              skin and blend it in together with the tahini after they have been
              left out to dry a little. For a more intense chickpea flavour,
              some of the liquid from the can can be saved and added to the
              hummus when blending.
            </li>
            <li>
              If it is too thick, add olive oil. Blend. Add olive oil sparingly,
              don't want it too liquidy.
            </li>
            <li>
              <i>Followed Inspired Taste’s recipe</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HummusModal;
