import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ExperienceModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Work Experience
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Manulife | Project Manager </h4>
        <ul>
          <li>
            Supervise three countries (China, Singapore, Cambodia) on six
            individual cybersecurity initiatives
          </li>
          <li>
            Conduct weekly meetings with countries’ developers and testers, to
            assist with strategic planning and find solutions to problematic
            tasks
          </li>
        </ul>
        <h4>Development Bank of Singapore (DBS) | Project Manager </h4>
        <ul>
          <li>
            Diagnosed reoccurring problems using VBA and Qualtrics to relay
            information to the IT team in Singapore
            <ul>
              <li>
                Delivered presentations about issues and possible timelines for
                the fixes
              </li>
              <li>
                Prevented severe app rating slippage helping to maintain a
                target score set by the management team
              </li>
            </ul>
          </li>
          <li>
            Collected and produced data models by building dashboards in Adobe
            Analytics to provide team with insight on which functions of the
            applications were underperforming
          </li>
        </ul>
        <h4>
          The HongKong and Shanghai Banking Corporation (HSBC) | Scrum Master{' '}
        </h4>
        <ul>
          <li>
            Managed Agile team’s developers along with the tickets in JIRA for
            two main projects: an internal chatbot for staff usage and an
            auto-form filler for HSBC onboarding consumers
          </li>
          <li>
            Assisted with cloud migration project - relocation of their legacy
            system on to the Google Cloud platform
          </li>
        </ul>
        <h4>Sierra Wireless | Junior Software Engineer </h4>
        <ul>
          <li>
            Developed Python/TCL scripts for cellular modem functional
            andverification tests of IoT FW
          </li>
          <li>
            Documentation written: Design documents, test reports, test plans
            and user guide
          </li>
        </ul>
        <h4>BlueRock | Junior Technology Consultant </h4>
        <ul>
          <li>Client meetings, proposing strategies and potential solutions</li>
          <li>Website Design (HTML, CSS, WordPress, SquareSpace)</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExperienceModal;
