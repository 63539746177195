import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RoastPotatoesModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Roast Potatoes (J. Kenji Lopez Alt.)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Picture might look underwhelming but this is the best way to make
              roasted potatoes
            </li>
            <li>
              Bring water to a boil, add salt, and a half teaspoon of baking soda
              then add the cut up potatoes. Why baking soda? The baking soda makes
              the water into an alkaline solution which breaks down the pectin
              of the potatoes' surface giving it a better crunch. When a knife
              goes through the potatoes with ease you're done.
            </li>
            <li>
              In a skillet, heat some olive oil, garlic, salt, whatever spices
              you want. Onces lightly toasted, dump the oil into the drained
              boiled potatoes and give it a rough shake until everything looks
              roughed up.
            </li>
            <li>450F</li>
            <li>
              <i>Followed J. Kenji Lopez Alt's Recipe</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RoastPotatoesModal;
