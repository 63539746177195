import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function RempahLaksaModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rempah Laksa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              I don't normally do this but this place was too good. Rempah
              Laksa.
            </li>
            <li>
              The broth was seafoody which I don't normally like but this one
              was bursting with flavours. Tangy, spicy, got a great umami
              flavour.
            </li>
            <li>
              Fresh ingredients. This place is booming because of their
              dedication to using fresh ingredients. Shrimps aren't frozen and
              mushy. Chicken is torn apart by hand. Laksa broth is just so good.
            </li>
            <li>
              <i>
                Rempah Laksa{' '}
                <a href="https://www.rempahnoodles.com/">
                  [https://www.rempahnoodles.com/]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RempahLaksaModal;
