import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function EggsBenedictModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Eggs Benedict
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Poached eggs: Use white vinegar to poach eggs to keep egg whites
              together
            </li>
            <li>
              Hollandaise sauce: One egg and an additional egg yolk, lemon
              juice, whisk until doubled in volume, slowly add butter
            </li>
            <li>
              If hollandaise splits, add tbsp hot water in bowl and slowly whisk
              in sauce
            </li>
            <li>
              <i>Followed J. Kenji López-Alt’s recipe</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EggsBenedictModal;
