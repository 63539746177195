import React from 'react';

function Message() {
  return (
    <div id="introduction">
      <div className="vertical-center">
        <p>
          404 ERROR: This page does not exist. Please go back to a page that
          does.
        </p>
      </div>
    </div>
  );
}

export default Message;
