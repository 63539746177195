import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function BibimbapModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Bibimbap</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Cutting up everything was challening. She liked it. I miss this
              dish. I miss cooking. I miss everything. I miss her.
            </li>
            <li>
              Learned how to julienne carrots. Make sure you get a flat bottom.
            </li>
            <li>
              Carrots were cooked in some honey and soy sauce. Bit of brown
              sugar. Was a tad too sweet.
            </li>
            <li>
              Meat was marinated in gochujang. Amazing. Always add a bit of
              sugar for marinating meets to help enhance the Maillard reaction.
            </li>
            <li>
              Bean sprouts lightly blanched, soaked in water and marinated in
              sesame oil and salt. Easy.{' '}
            </li>
            <li>
              <i>
                Didn't really follow a recipe but can reference link below{' '}
                <a href="https://mykoreankitchen.com/bibimbap-korean-mixed-rice-with-meat-and-assorted-vegetables/">
                  [https://mykoreankitchen.com/bibimbap-korean-mixed-rice-with-meat-and-assorted-vegetables/]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BibimbapModal;
