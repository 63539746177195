import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function DanDanModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Dan Dan Noodles
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Sesame paste is very heavy and a dominating flavour. Use it
              reservingly or the dish can get a bit too heavy. Whatever recipes
              call for, use half to start.
            </li>
            <li>
              Thai chilis are a great addition to this. Spicier the better.
            </li>
            <li>
              The acidity in the black vinegar helps cut through the intense
              flavour in the sesame paste.
            </li>
            <li>
              Try to find fresh cut Shanghai noodles for this recipe. Leftover
              noodles are no good so don't make too much!
            </li>
            <li>
              A nice minced would have been much better than chicken here. Note
              to self for next time.
            </li>
            <li>
              <i>
                Followed The Wok’s of Life's recipe{' '}
                <a href="https://thewoksoflife.com/dan-dan-noodles">
                  [https://thewoksoflife.com/dan-dan-noodles]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DanDanModal;
