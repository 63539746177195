import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function EducationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Education</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>University (2014-2019) </h4>
        <p>Queen's University (Kingston, Ontario)</p>
        <ul>
          <li>
            Bachelor of Applied Science with Professional Internship <br></br>
            <ul>
              <li>Specialization in Software Engineering</li>
            </ul>
          </li>
        </ul>
        <h4>Secondary School (2010-2014)</h4>
        <p>Crescent School (Toronto, Ontario)</p>
        <ul>
          <li>Headmaster’s Honour Roll Distinction (&gt;93%)</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EducationModal;
