import React from 'react';
import chefShark from '../../resources/chefShark.png';

function Introduction() {
  return (
    <div class="containerRecipe">
      <div class="profile">
        <div class="profile-image">
          <img src={chefShark} alt="" />
        </div>
        <div class="profile-bio">
          <p>
            Cooking helps me relax and it gives me a chance to make dishes that
            I would never have thought I could make. This page will hold some of
            the recipes that I have made in the past,and some of the lessons I
            have learned along the way.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
