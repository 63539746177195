import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function PineappleFRModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pineapple Fried Rice with toasted garlic chips
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Undercook the rice if you are doing it the day of. Add less water
              to cook the rice to achieve this.
            </li>
            <li>
              In some oil, lightly fry up some garlic. Keep the garlic on the
              side, keep the oil to fry up the rest of the ingredients.
            </li>
            <li>
              Make sure the rest of the ingredients have all the water wrung
              out. Soggy fried rice is no good. Fish sauce and more spice would
              have gone well with this.
            </li>
            <li>
              Technique some Japanese chefs use is mixing cooked rice with some
              eggs before frying. This has been tried by me already and it makes
              the rice a little more moist. Could have made it better.
            </li>
            <li>
              Making a special soy sauce before cooking everything is also a
              great idea. No need to rush and have more precision.
            </li>
            <li>
              <i>No recipe in particular was followed here</i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PineappleFRModal;
