import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function CarbonaraModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Carbonara</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Egg yolk, black pepper, grana padano. That's it. Pancetta/guanciale if
              you want.
            </li>
            <li>
              Fry up the pancetta, and toast black pepper. Under cook the pasta, toss
              it in the pan.
            </li>
            <li>
              MAKE SURE THERE IS ENOUGH FAT OR THE SAUCE IS GOING TO CURDLE.
              Especially after adding pasta water, not a lot of fat leads to a
              grainy and gross sauce. Turn off heat before adding cheese.
            </li>
            <li>
              <i>
                Followed Carla Lalli’s recipe{' '}
                <a href="https://www.bonappetit.com/recipe/traditional-carbonara">
                  [https://www.bonappetit.com/recipe/traditional-carbonara]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CarbonaraModal;
