import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function LimonePastaModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Limone Pasta
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Tips</h4>
        <p>
          <ul>
            <li>
              Lemon juice and lemon zest brings an acidity to the pasta.
              Something I have never tried before.
            </li>
            <li>
              Sauce curdled. Two reasons for that - not enough fat in the sauce
              (butter or more cheese), or the pan was too hot when I added the
              cheese. Takeaway: for cream sauce, make sure to use butter before
              adding the cream, and make sure to turn off the heat before adding
              the cheese. Adding pasta water is good but it might take away the
              mol/L of the fat so add it sparingly.
            </li>
            <li>
              Chicken was awesome. Just a bit of a dredge - nothing fancy.
            </li>
            <li>
              <i>
                Followed Molly Baz’s recipe{' '}
                <a href="https://www.bonappetit.com/recipe/pasta-al-limone">
                  [https://www.bonappetit.com/recipe/pasta-al-limone]
                </a>
              </i>
            </li>
          </ul>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LimonePastaModal;
